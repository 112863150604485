import React from 'react'
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrow from "../assets/images/svg/right-arrow-full.svg";
// import '../assets/css/ProjectDetails.scss'
import { useState, useEffect, useRef } from 'react'
import 'swiper/css/bundle'
import { Game, games } from '../helper/gameDetails';
import { Footer } from './Footer';
import itch from "../assets/images/itch.webp"

export const ProjectDetail = () => {

  const useSwiperRef = <T extends HTMLElement>(): [T | null, React.Ref<T>] => {
    const [wrapper, setWrapper] = useState<T | null>(null)
    const ref = useRef<T>(null)
  
    useEffect(() => {
      if (ref.current) {
        setWrapper(ref.current)
      }
    }, [])
  
    return [wrapper, ref]
  }

  const [nextEl, nextSlideRef] = useSwiperRef<HTMLDivElement>()
  const [prevEl, prevSlideRef] = useSwiperRef<HTMLDivElement>()

  const {id} = useParams();
  const [game, setGame] = useState<Game>({gameTitle:'', description_short:'', description_long:'', img:[], link:''});

  const findGame = () =>{
    for(let i = 0; i < games.length;i++){
      if(games[i].gameTitle == id.replaceAll('-',' ')){
        setGame({gameTitle: games[i].gameTitle, description_short: games[i].description_short, 
                description_long: games[i].description_long, img:games[i].img, link: games[i].link})
      }
    }
  }

  useEffect(() =>{
    findGame();
    
  },[])

  return (
    <>
      <section id='project-page' className='project-page-section'>

        <div className='profile-container project-details'>
          <header>
            <h2 >projects. </h2>
          </header>

          <div className='project-details-container'>
            <Swiper
              lazy={true}
              initialSlide={1}
              className='project-details-swiper'
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation={{
                prevEl,
                nextEl,
              }}
              speed={700}
              pagination={{
                //type:"",
                el:`.pagination-div`,
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '">' + "</span>";
                },
              }}
              loop={true}
            >

              {game.img.map((element, index)=>(
                <SwiperSlide key={index}>
                  <motion.div className='project-images'
                    initial={{opacity: 0, y: 40}}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ 
                      ease: 'easeInOut', 
                      duration: 1.5, 
                      delay: 0.3,
                    }}
                    viewport={{ once: true }}
                  >
                    <figure>
                      <img src={element}></img>
                    </figure>
                  </motion.div>
                </SwiperSlide>
              ))}


            </Swiper>

            <div className='details-carousel-controls' style={{cursor: "auto"}}>
              <div className='prev-slide-button' ref={prevSlideRef}></div>
              <div className='pagination-div'></div>
              <div className='next-slide-button' ref={nextSlideRef}></div>
            </div>

            <motion.div className='projects-details-body'
              initial={{opacity: 0, y: 40}}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                ease: 'easeInOut', 
                duration: 1.5, 
                delay: 0.3,
              }}
              viewport={{ once: true }}
            >
              
              <h2> {game.gameTitle}</h2>
              
              <p className='project-details-description'>{game.description_long}</p>
            </motion.div>

            <a className='project-details-link' href={game.link} target='_blank'>itch.io: <img  src={itch} alt='itch.io'title='itch.io'></img></a>
          </div>

        </div>
        


      </section>
      <motion.div className='link-to-all'
        initial={{opacity: 0}}
        whileInView={{ opacity: 1}}
        transition={{ 
          ease: 'easeInOut', 
          duration: 1.5, 
          delay: 0.3,
        }}
        viewport={{ once: true }}
      >
        <NavLink to="/projectsList"><p>back to projects <img src={arrow}/></p></NavLink> 
      </motion.div>
      {/* <SideBar/> */}
      <Footer/>
    </>
  )
}