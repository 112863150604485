import screenshot from '../assets/images/dont-sink/gameplay_1-2.png';
import screenshot2 from '../assets/images/dont-sink/dont-sink.png';
import screenshot3 from '../assets/images/dont-sink/dont-sink2.png';
import screenshot4 from '../assets/images/dont-sink/dont-sink3.png';

import screenshot5 from '../assets/images/missing-link/missingLink2.png';
import screenshot6 from '../assets/images/missing-link/missingLink4.png';
import screenshot7  from '../assets/images/missing-link/missingLink6.png';

import screenshot8  from '../assets/images/ultimateshop.png';
import screenshot9  from '../assets/images/ultimateshop2.png';
import screenshot10 from '../assets/images/ultimateshop3.png';




export interface gameProps{
    games: Game[]
}
  
export interface Game{
  gameTitle:string,
  description_short:string,
  description_long:string,
  img: string[],
  link: string,
}

export const games = [
    {
        gameTitle: "Don't Sink!",
        description_short: "Many things can go wrong while running a ship! Take control and try to not sink for as long as you can in this endless action game with randomly generated paths, obstacles and problems to fix each time you restart the game!",
        description_long: "Many things can go wrong while running a ship! Take control and try to not sink for as long as you can in this endless action game with randomly generated paths, obstacles and problems to fix each time you restart the game! \n \n This game was originally developed using in-house assets and shaders created by the team's artist. It features a simple yet adequate procedural terrain generation of which its development was single-handedly led by myself to attempt and deliver the concept of a casual yet adventurous game while at the same time adhering to the given specifications at the time.",
        img: [screenshot,screenshot2, screenshot3, screenshot4],
        link: 'https://hwijaya.itch.io/dont-sink',
    }, 

    {
        gameTitle: "The Missing Link",
        description_short: "A point-and-click mystery game where you play as an aspiring investigator named George, as he discovers the secrets behind a shadow organisation threatening to take over the city of San Minuto. ",
        description_long: "A point-and-click mystery game where you play as an aspiring investigator named George, as he discovers the secrets behind a shadow organisation threatening to take over the city of San Minuto. \n \n  The Missing Link was initially visualized to be a multi-chapter mystery game where the prologue was released as a submission for the Unimelb UniJam whilst at the same time being one my earliest attempts at game development.",
        img: [screenshot5,screenshot6, screenshot7],
        link: 'https://team-sparrow.itch.io/the-missing-link',
    },

    {
        gameTitle: "The Ultimate Shopper",
        description_short: "Take part in a new and exciting survival show known as 'The Ultimate Shopper', where contestants have to race to complete shopping lists before the time expires. However, this won't be your run of the mill grocery shopping run, as the contestants are placed into dangerous supermarkets filled with a variety of life-threatening challenges!",
        description_long: "Take part in a new and exciting survival show known as 'The Ultimate Shopper', where contestants have to race to complete shopping lists before the time expires. However, this won't be your run of the mill grocery shopping run, as the contestants are placed into dangerous supermarkets filled with a variety of life-threatening challenges! \n \n Inspired by games such as Overcooked!, The Ultimate Shopper intends to create a similar gripping experience for its players with its fast-paced gameplay. It was created as a submission for the 2021 Brackeys Game Jam with in-house visual assets and sourced audio effects.",
        img: [screenshot8, screenshot10],
        link: 'https://team-sparrow.itch.io/the-ultimate-shopper',
    }
]

