import React from 'react'
import { motion, Variants } from 'framer-motion';
import { About } from './About';
import { Projects } from './Projects';
import { Footer} from './Footer';
import { SectionTitleBar } from './SectionTitleBar';
import { NavLink } from "react-router-dom";
import arrow from "../assets/images/svg/right-arrow-full.svg";

const heroLetters: Variants = {
  initial: { y: 40, opacity:0 },
  visible: { opacity: 1, y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 0.25,
    },
  },
};

const heroName: Variants = {
  initial: { opacity: 0 },
  visible: { opacity:1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

export const Home: React.FC<{title: string}> = ({title}) => {
  return (
    <>
    <section className='top' id = 'home'>
        <div className='inner-top-container'>

          <motion.h1
            variants={heroName}
            initial='initial'
            animate='visible'
            
            >
            {[...title].map((letter, i) => (
              <motion.span
              key={i}
              variants={ heroLetters }
              >
              {letter}
              </motion.span>

            ))}
          
          </motion.h1>

          <motion.p
            initial={{ opacity:0 }}
            animate={{ opacity:1 }}
            transition={{ 
              ease: 'easeInOut', 
              duration: 1, 
              delay: 0.6,
            }}
            >
            
            <span>
              game / front-end developer
            </span> 
          
          </motion.p>

      </div>
    </section>

    <About/>
    <SectionTitleBar/>
    <Projects/>

    <motion.div className='link-to-all'
        initial={{opacity: 0}}
        whileInView={{ opacity: 1}}
        transition={{ 
          ease: 'easeInOut', 
          duration: 1.5, 
          delay: 0.3,
        }}
        viewport={{ once: true }}
      >
      <NavLink to="/projectsList"><p>view all projects <img src={arrow}/></p></NavLink> 
    </motion.div>

    <Footer/>
    </>
  )
}

