import React from 'react'
import { motion} from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrow from "../assets/images/svg/right-arrow-full.svg";
import { useState, useEffect, useRef } from 'react'
import 'swiper/css/bundle'
import { Game } from '../helper/gameDetails';

interface projectProps{
  game: Game,
  orderType:string,
  id:number,
}

export const Project: React.FC<projectProps> = (props) => {

  const portraitMobile = window.matchMedia("(orientation: portrait)").matches && props.orderType == 'second';

  const useSwiperRef = <T extends HTMLElement>(): [T | null, React.Ref<T>] => {
    const [wrapper, setWrapper] = useState<T | null>(null)
    const ref = useRef<T>(null)
  
    useEffect(() => {
      if (ref.current) {
        setWrapper(ref.current)
      }
    }, [])
  
    return [wrapper, ref]
  }

  const [nextEl, nextSlideRef] = useSwiperRef<HTMLDivElement>()
  const [prevEl, prevSlideRef] = useSwiperRef<HTMLDivElement>()

  return (
      <div className='outer-container'>
        <motion.div className={`projects-bg ${props.orderType}Project`}
          initial={{opacity: 0, x: portraitMobile ? -200: 200}}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ 
            ease: 'easeInOut', 
            duration: 1.5, 
            delay: 0.6,
          }}
          viewport={{ once: true }}
        >
        <div className='projects-container'>
          <Swiper
            lazy={true}
            id={props.id.toString()} 
            className='swiper-gallery firstProject'
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation={{
              prevEl,
              nextEl,
            }}
            speed={700}
            pagination={{
              el:`.pagination-div-${props.id}`,
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="' + className + '">' + "</span>";
              },
            }}
            loop={true}
          >
            
            {props.game.img.map((element, index)=>(
              <SwiperSlide key={index}>
                <motion.div className='project-images'
                  initial={{opacity: 0, y: 40}}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ 
                    ease: 'easeInOut', 
                    duration: 1.5, 
                    delay: 0.3,
                  }}
                  viewport={{ once: true }}
                >
                  <figure>
                    <img src={element}></img>
                  </figure>
                </motion.div>
              </SwiperSlide>
            ))}
            

          </Swiper>

          <motion.div className='projects-body'
            initial={{opacity: 0, y: 40}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ 
              ease: 'easeInOut', 
              duration: 1.5, 
              delay: 0.3,
            }}
            viewport={{ once: true }}
          >
            <header>
              <h2> {props.game.gameTitle}</h2>
            </header>

            <p className='project-description'>{props.game.description_short}</p>

            <NavLink to={`/projectsList/${props.game.gameTitle.replaceAll(' ', '-')}`}><p className='link'>learn more  <img src={arrow}/></p></NavLink>
          </motion.div>

        </div>

        <div className='carousel-controls' style={{cursor: "auto"}}>
          <div className='prev-slide-button' ref={prevSlideRef}></div>
          <div className={`pagination-div pagination-div-${props.id}`}></div>
          <div className='next-slide-button' ref={nextSlideRef}></div>
        </div>
        </motion.div>

      </div>
  )
}