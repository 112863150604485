// import '../assets/css/projects.scss'
import { Project } from './IndividualProjectCard';
import { games } from '../helper/gameDetails';

export const Projects = () => {

  const portrait = window.matchMedia("(orientation: portrait)").matches;

  return (
    <section id='projects' className='projects-section'>

      {window.location.pathname == '/' ? <div className='mobile-title'>projects.</div>: null}
      
        <ul>
          <li>
            {window.location.pathname == '/' ? <Project game={games[0]} orderType={'first'} id={1}/>
            : <Project game={games[0]} orderType={'firstList'} id={1}/>}
          </li>

          <li>
            <Project game={games[1]} orderType={'second'} id={2}/>
          </li>

          {window.location.pathname == '/projectsList' ? 

          <li>
            <Project game={games[2]} orderType={'third'} id={3}/>
          </li>

          : null}
        </ul>
    </section>
  )
}