import { Projects } from './Projects';
import { Footer } from './Footer';

export const ProjectsList = () => {
  return (
    <>
    <section id='projectsList' className='projectsList-section'>

    <div className='profile-container'style={{margin: '5% auto 0 auto'}}>
        <header>
          <h2 >projects.</h2>
        </header>
    </div>

    <Projects/>
    <Footer/>
    </section>
    </>
  )
}