import { motion, Variants, AnimatePresence } from 'framer-motion';
// import '../assets/css/SideBar.scss'
import arrow from "../assets/images/svg/left-arrow-thin.svg";
import mail from "../assets/images/svg/mail.svg"
import linkedin from "../assets/images/svg/linkedin-color.svg"
import itch from "../assets/images/itch.webp"


export const SideBar = () => {

    const backToTop = () =>{
        var Scroll = require('react-scroll');
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    const sectionTitle: Variants = {
        initial: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0,
          transition:{ 
            ease: 'easeInOut', 
            duration: 0.8, 
            delay: 0.3,
          },
        },
        exit: {opacity: 0, 
          transition:{ 
            ease: 'easeInOut', 
            duration: 10, 
            delay: 0.5,
          }, 
        },
      };

    return (
      
      <AnimatePresence>
        
        <motion.div className='sideBar'
  
        initial= 'initial'
        variants={sectionTitle}
        animate= 'visible'
        >
  
  
          <ul>
            <li>
              <a href='https://hwijaya.itch.io/' target={'_blank'}><img className='scroll-button button-4' src={itch} alt='itch.io' title='itch.io'></img></a>
            </li>
  
            <li>
              <a href='https://www.linkedin.com/in/hans-wijaya-b35070185/' target={'_blank'}><img className='scroll-button button-3' src={linkedin} alt='Linkedin' title='Linkedin'></img></a>
            </li>
  
            <li>
              <a href='mailto:wijayahans360@gmail.com'><img className='scroll-button button-2' src={mail} alt='Mail' title='Get in Touch!'></img></a>
            </li>
  
            <li>
              <img className='scroll-button button-1' src={arrow} alt='scrollTopButton' onClick={backToTop} title='Back to Top'></img>
            </li>
          </ul>
          
  
        </motion.div>
      </AnimatePresence>
    )
  }