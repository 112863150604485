// import '../assets/css/about.scss'
import { motion} from 'framer-motion';
import { NavLink } from 'react-router-dom';
import arrow from "../assets/images/svg/right-arrow-full.svg";

export const About = () => {

  return (
  <>
  <section id='about' className='about-section'>
    
    <div className='about-container'>
      
      <motion.div className='about-body'
        initial={{opacity: 0, y: 40}}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ 
          ease: 'easeInOut', 
          duration: 1.5, 
          delay: 0.3,
        }}
        viewport={{ once: true }}
      >
        <header>
          <h2>i like to design and build things, games in particular.</h2>
          <p className='about-description'>driven by my passion for games, i decided to learn and make my own, hoping to inspire others along the way.</p>
          <NavLink to="/full-profile"><p className='about-link'>about me  <img src={arrow}/></p></NavLink> 
          {/* <a href="/full-profile"><p className='about-link'>about me  <img src={arrow}/></p></a> */}
        </header>

      </motion.div>

    </div>
    
    {/* <svg height={4976} width={1440} >  */}
      {/* <path d='M350 200  Q340 205 350 210 L700 400 Q780 440 775 420 L600 50 Q590 45 580 50'></path> */}
      {/* <path d='M1225 186 V0 L906 0 V186 L1225 186 Z' fill='#b4a497'></path> */}
      {/* <path d='M211 295 C187 295 163 302 143 315 C226 474 219 476 211 476' fill='F2C2C2'></path>
      <path data-strength="M" d="M211.734 295C187.46 295 163.73 302.198 143.547 315.684C123.363 329.171 107.632 348.339 98.3426 370.766C89.0532 393.193 86.6226 417.87 91.3584 441.678C96.0941 465.486 107.783 487.356 124.948 504.52C142.113 521.685 163.982 533.374 187.79 538.11C211.598 542.846 236.276 540.415 258.702 531.126C281.129 521.836 300.298 506.105 313.784 485.921C327.27 465.738 334.468 442.009 334.468 417.734C334.468 385.183 321.537 353.965 298.52 330.948C275.503 307.931 244.285 295 211.734 295V295ZM211.734 476.015C200.207 476.015 188.939 472.597 179.355 466.193C169.771 459.789 162.301 450.686 157.89 440.037C153.479 429.388 152.325 417.669 154.573 406.364C156.822 395.059 162.373 384.674 170.523 376.524C178.674 368.373 189.059 362.822 200.364 360.573C211.669 358.325 223.388 359.479 234.037 363.89C244.686 368.301 253.789 375.771 260.193 385.355C266.597 394.939 270.015 406.207 270.015 417.734C270.027 425.391 268.527 432.975 265.603 440.051C262.678 447.128 258.386 453.557 252.971 458.971C247.557 464.386 241.128 468.678 234.051 471.603C226.975 474.527 219.391 476.027 211.734 476.015Z" fill="#F2C2C2" style="transform-origin: 0px 0px 0px;" data-svg-origin="89 295" transform="matrix(1,0,0,1,0,109.2255)"></path> */}
    {/* </svg> */}
  
  </section>
  </>
    
  )
}
