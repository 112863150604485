import { motion, Variants, useScroll, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react'
// import '../assets/css/sectionTitle.scss'

export const SectionTitleBar = () => {

  const sectionTitle: Variants = {
    initial: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0,
      transition:{ 
        ease: 'easeInOut', 
        duration: 0.8, 
        delay: 0.3,
      },
    },
    exit: {opacity: 0, 
      transition:{ 
        ease: 'easeInOut', 
        duration: 10, 
        delay: 0.5,
      }, 
    },
  };

  const scrollTop: Variants = {
    initial: { opacity: 0},
    visible: { opacity: 1,
      transition:{ 
        ease: 'linear', 
        duration: 1, 
        delay: 0.3,
      },
    },
    exit: {opacity: 0, 
      transition:{ 
        ease: 'easeInOut', 
        duration: 10, 
        delay: 0.5,
      }, 
    },
  };

  const sectionTitleChild: Variants = {
    initial: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0,
      transition:{ 
        ease: 'easeInOut', 
        duration: 0.8, 
        delay: 0.3,
      },
    },
    
  };

  const {scrollYProgress} = useScroll()

  const sectionTitles: string[] = ['about', 'projects'];

  //const vh = Math.max(document.documentElement.clientHeight);
  
  const [currentSectionTitle, setCurrentSectionTitle] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [attached, setAttached] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position);
    //console.log(position);
  };

  
  // const box = document.querySelector('#home') as HTMLElement;
  // const rect = box.getBoundingClientRect();

  // function isInViewport(element: HTMLElement) {
  //   const rect = element.getBoundingClientRect();
  //   return (
  //       rect.top >= 0 &&
  //       rect.left >= 0 &&
  //       rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //       rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }

  // console.log(isInViewport(box));

  // const box = document.querySelector('.sectionTitleMain') as HTMLElement;
  // const rect = box.getBoundingClientRect();
  // console.log(rect.top);

  // function getRGB(c) {
  //   return parseInt(c, 16) || c
  // }
  
  // function getsRGB(c) {
  //   return getRGB(c) / 255 <= 0.03928
  //     ? getRGB(c) / 255 / 12.92
  //     : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
  // }
  
  // function getLuminance(hexColor) {
  //   return (
  //     0.2126 * getsRGB(hexColor.substr(1, 2)) +
  //     0.7152 * getsRGB(hexColor.substr(3, 2)) +
  //     0.0722 * getsRGB(hexColor.substr(-2))
  //   )
  // }
  
  // function getContrast(f, b) {
  //   const L1 = getLuminance(f)
  //   const L2 = getLuminance(b)
  //   return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
  // }
  
  // function getTextColor(bgColor) {
  //   const whiteContrast = getContrast(bgColor, '#ffffff')
  //   const blackContrast = getContrast(bgColor, '#000000')
  
  //   return whiteContrast > blackContrast ? '#ffffff' : '#000000'
  // }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });


    const portrait = window.matchMedia("(orientation: portrait)").matches;
    
    //console.log(window.scrollY);
    const element = document.querySelector('.sectionTitleMain') as HTMLElement | null

    function updateSection() {

      // if(element!=null){
      //   element.style.opacity = Math.max(0, Math.min(1, -window.pageYOffset / 700 + 2)).toString();
      //   console.log(element.style.opacity)
      // }

      const sideBar = document.querySelector('.sideBar') as HTMLInputElement | null;

      
      const home = document.querySelector('.top') as HTMLElement;
      const homeRect = home.getBoundingClientRect();

      const about = document.querySelector('.about-section') as HTMLElement;
      const aboutRect = about.getBoundingClientRect();

      const projects = document.querySelector('.projects-section') as HTMLElement;
      const projectsRect = projects.getBoundingClientRect();

      const footer = document.querySelector('.footer-section') as HTMLElement;
      const footerRect = footer.getBoundingClientRect();

      if(aboutRect.top < about.offsetHeight/2 && (-aboutRect.top < about.offsetHeight/2)){

        setCurrentSectionTitle(sectionTitles[0]);
        setIsVisible(true);

        if(element != null){
          element.style.height = '260px';
          element.style.left = '0'; 
        }
      }

      if(aboutRect.top <= 0  && !attached){

        if(element != null){
          element.style.position = 'fixed';
          element.style.top = '0px';
        }
      }

      if(homeRect.top <= -300){
        if(sideBar != null){
          sideBar.style.opacity = '1';
          sideBar.style.opacity = '1';
        }
      }

      if(homeRect.top >= -300){
        if(sideBar != null){
          sideBar.style.opacity = '0';
          sideBar.style.opacity = '0';
        }
      }

      if(aboutRect.top >= 0){

        if(element != null){
          element.style.position = 'absolute';
          element.style.top = '100vh'; 
        }
      }

      if(projectsRect.top <= 0){
        setCurrentSectionTitle(sectionTitles[1]);

        if(element != null){
          element.style.height = '360px';
        }
      }

      if((footerRect.top <= window.innerHeight) && !attached){
        if(element != null){
          element.style.position = 'absolute';
          element.style.top = (window.scrollY).toString()+"px";
          setAttached(true);
        }
      }


      if((footerRect.top >= window.innerHeight) && attached){
        if(element != null){
          element.style.position = 'fixed';
          element.style.top = '0px';
          setAttached(false);
        }
      }
    }

    updateSection();

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };

  }, [scrollPosition]);

  return (
    
    <AnimatePresence>
      {(isVisible ? <>
        <motion.div className={'sectionTitleMain'}
      
          initial= 'initial'
          variants={sectionTitle}
          animate= 'visible'
          >

          <motion.span
            initial= 'initial'
            variants={sectionTitleChild}
            animate= 'visible'
            key={currentSectionTitle}
          >
            <p>{currentSectionTitle}</p>
            {/* {scrollPosition/vh} */}
          </motion.span> 

          
        </motion.div>
        
        </>
        :
        <></>
      )}
      </AnimatePresence>
  )
}