// import "../assets/css/profile.scss"
import { motion} from 'framer-motion';
import { Footer } from './Footer';
import photo from '../assets/images/profile.jpg'
import icon from "../assets/images/svg/download-pdf.svg"; 
import resume from '../assets/final-resume.pdf'

export const Profile = () => {

  return (
    <>
    <section id='full-profile' className='profile-section'>

      <div className='profile-container'>
        <header>
          <h2>about.</h2>
        </header>

        <motion.div className='profile-contents'
          initial={{opacity: 0, y: 40}}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ 
            ease: 'easeInOut', 
            duration: 1.5, 
            delay: 0.3,
          }}
          viewport={{ once: true }}
        >

          <motion.div className='full-profile-image mobile'
            initial={{opacity: 0, y: 40}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ 
            ease: 'easeInOut', 
            duration: 1.5, 
            delay: 0.3,
            }}
            viewport={{ once: true }}
          >
            <figure>
              <img src={photo}></img>
            </figure>
          </motion.div>
          

          <div className='profile-body'>
            <div className='name-title'>
                <h2>Hans Wijaya</h2>
                <p>game / front-end developer</p>
            </div>

            <div className='sub-heading'>
              <p></p>
            </div>

            <div className='profile-description'>
              <p>{"I'm a developer based in Jakarta, Indonesia specializing in making video games and websites, usually of my own design. Recently, I graduated from the University of Melbourne with a Bachelor of Science degree, majoring in computing and software systems and is currently looking for opportunities to create experiences that can inspire others."}</p>
              <br/>
              <p>{"Beyond all that, I'm a huge coffee enthusiast and gamer who likes to play competitively! For me, everything I do is based on my belief in an 'all or nothing' mentality because from my point of view, great ideas can turn bad with half-baked effort."}</p>
            </div>
          </div>

          <motion.div className='full-profile-image'
            initial={{opacity: 0, y: 40}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ 
            ease: 'easeInOut', 
            duration: 1.5, 
            delay: 0.3,
            }}
            viewport={{ once: true }}
          >
            <figure>
              <img src={photo}></img>
            </figure>
          </motion.div>

        </motion.div>


        <div className='skills'>
          {/* <a href={resume} target={'_blank'}><img src={icon} title={'Click Here to Learn More! (My Resume)'}/></a> */}
          <h2>some of the things i'm good at </h2>
          <ul>
            <li>
              C# (Unity)
            </li>
            <li>
              React JS / React Redux
            </li>
            <li>
              TypeScript
            </li>
            <li>
              Python
            </li>
            <li>
              C
            </li>
            <li>
              Css / Sass
            </li>
            <li>
              Java
            </li>
          </ul>
        </div>
        
      </div>

      
    </section>
    {/* {window.location.href == '/full-profile' ? <SideBar/> : null} */}
    <Footer/>
    </>
  )
}