import './assets/css/common.min.scss';
import { Profile } from './components/full-profile'
import { Route, Routes, BrowserRouter} from 'react-router-dom';
import { Home } from './components/Home';
import { ScrollToTop } from './components/ScrollToTop.js';
import { ProjectsList } from './components/ProjectList';
import { ProjectDetail } from './components/ProjectDetail';


function App() {

  return (
  
    <div className="App">
      
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home title={"Hans Wijaya"}/>}/>
          <Route path='/full-profile' element={<Profile/>}/>
          <Route path='/projectsList' element={<ProjectsList/>}/>
          <Route path='/projectsList/:id' element={<ProjectDetail/>}/>
        </Routes>
      </BrowserRouter>
      
    </div>
  
  );
}

export default App;
