import { motion} from 'framer-motion';
import { NavLink } from 'react-router-dom';
// import '../assets/css/footer.scss'
import { SideBar } from './SideBar';

export const Footer = () => {
  return (
    <section id='footer' className='footer-section'>
        <div className='footer-container'>
          <SideBar/>
          <motion.div className='footer-body'
            initial={{opacity: 0}}
            whileInView={{ opacity: 1}}
            transition={{ 
              ease: 'easeInOut', 
              duration: 1.5, 
              delay: 0.3,
            }}
            viewport={{ once: true }}
          >
            <header>
              <h2>Hans Wijaya</h2>
              <p>copyright © Hans Wijaya, All rights Reserved</p>
              {/* <p style={{paddingTop:"2rem"}}>im always open to new opportunities</p> */}
            </header>
            
            <div className='footer-nav'>
              
              <ul>

                <li>
                  <a href='/'>top</a>
                </li>
                <li>
                  <NavLink to="/full-profile">about</NavLink> 
                  {/* <a href='/full-profile'>about</a> */}
                </li>
                <li>
                  <NavLink to="/projectsList">projects</NavLink> 
                  {/* <a href='/projectsList'>projects</a> */}
                </li>
              </ul>

            </div>
            
          
          </motion.div>
        </div>
    </section>
  )
}